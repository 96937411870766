import type { ReactNode } from 'react';
import React, { Fragment, useState, useContext, useCallback } from 'react';

import { useEditSource } from '@confluence/load-edit-page/entry-points/useEditSource';

import { EditContentButtonPreloaderContext } from './EditContentButtonPreloaderContext';

export interface ICreateContentButtonPreloader {
	children: ReactNode;
	editSource?: string;
}

export const CreateContentButtonPreloader = ({
	children,
	editSource = '',
}: ICreateContentButtonPreloader) => {
	const preloader = useContext(EditContentButtonPreloaderContext); // See packages/confluence-fabric-editor/src/editorPreloader.tsx
	const [doPreload, setDoPreload] = useState(false);

	const [{ editSource: contextEditSource }, { setEditSource }] = useEditSource();

	const onHover = useCallback(() => {
		setEditSource(editSource);
		setDoPreload(true);
	}, [editSource, setEditSource]);

	return (
		<Fragment>
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
			<div onMouseEnter={onHover}>{children}</div>
			{editSource === contextEditSource && doPreload && preloader ? preloader({}) : null}
		</Fragment>
	);
};
