import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import BlogIcon from '@atlaskit/icon/core/quotation-mark';

import { Ati } from '@atlassian/ari';

import { useHardStorageEnforcement } from '@confluence/storage-enforcement/entry-points/HardEnforcement/useHardStorageEnforcement';

import type { UseMenuItemProps, UseMenuItemReturnProps } from '.';

import { buildCreationLink } from '../../CreateContentRouteComponent';
import { useHybridLinkClick } from '../../CreateContentPopover/CreateContentPopoverItems/hybridLinkHelper';

const messages = defineMessages({
	title: {
		id: 'create-blank-fabric-page.universal-create.blog.title',
		defaultMessage: 'Blog',
		description: 'title indicating that this option will create a page',
	},
});

export function useBlogItem({
	source,
	spaceKey,
	onClick,
}: UseMenuItemProps): UseMenuItemReturnProps {
	const { formatMessage } = useIntl();

	const { enforceStorageLimit, shouldBlockCreate } = useHardStorageEnforcement({
		source: 'create-blog-popover-item',
	});

	const createHref = buildCreationLink({
		contentType: 'blog',
		withFallback: true,
		source,
		spaceKey,
	});

	const hybridPageLinkClick = useHybridLinkClick(createHref);

	const handleClick = useCallback(
		(e: React.MouseEvent | React.KeyboardEvent) => {
			onClick('createBlogPopoverItem');
			hybridPageLinkClick(e);
		},
		[onClick, hybridPageLinkClick],
	);

	return {
		ati: Ati.parse('ati:cloud:confluence:blogpost'),
		children: formatMessage(messages.title),
		onClick: enforceStorageLimit(handleClick),
		elemBefore: <BlogIcon label="" />,
		...(!shouldBlockCreate && { href: createHref }),
		testId: `create-menu-${shouldBlockCreate ? 'button' : 'link'}-item`,
	};
}
