import React, { useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import DatabaseIcon from '@atlaskit/icon/core/database';

import { Ati } from '@atlassian/ari';

import { useHardStorageEnforcement } from '@confluence/storage-enforcement/entry-points/HardEnforcement/useHardStorageEnforcement';
import { CreateDatabaseGAPopoverItemSpotlight } from '@confluence/databases-onboarding-ga';
import { useSkippableCoordination } from '@confluence/skippable-coordination-client';

import type { UseMenuItemProps, UseMenuItemReturnProps } from '.';

import { buildCreationLink } from '../../CreateContentRouteComponent';
import { useHybridLinkClick } from '../../CreateContentPopover/CreateContentPopoverItems/hybridLinkHelper';

const messages = defineMessages({
	title: {
		id: 'create-blank-fabric-page.universal-create.page.database',
		defaultMessage: 'Database',
		description: 'title indicating that this option will create a database',
	},
});

export function useDatabaseItem({
	parentId,
	source,
	spaceKey,
	onClick,
}: UseMenuItemProps): UseMenuItemReturnProps {
	const { formatMessage } = useIntl();

	const { enforceStorageLimit, shouldBlockCreate } = useHardStorageEnforcement({
		source: 'create-database-popover-item',
	});

	const createContentOptions = {
		contentType: 'database',
		parentPageId: parentId,
		withFallback: true,
		source,
		spaceKey,
	} as const;

	const [isSpotlightOpen, setIsSpotlightOpen] = useState(true);

	const [isDatabaseGAOnboardingActive, stopDatabaseGAOnboarding] = useSkippableCoordination(
		'cc-databases-ga-create-spotlight',
		shouldBlockCreate,
	);

	const handleSpotlightClose = useCallback(() => {
		setIsSpotlightOpen(false);
		void stopDatabaseGAOnboarding();
	}, [stopDatabaseGAOnboarding]);

	const createHref = buildCreationLink(createContentOptions);
	const hybridPageLinkClick = useHybridLinkClick(createHref);

	const handleClick = (e: React.MouseEvent | React.KeyboardEvent) => {
		onClick('createDatabasePopoverItem');
		hybridPageLinkClick(e);
	};

	const DatabaseSpotlightWrapper = ({ children }) => {
		return (
			<CreateDatabaseGAPopoverItemSpotlight
				createHref={createHref}
				source={source}
				onClose={handleSpotlightClose}
				dialogPlacement="right middle"
				shouldWatchTarget
			>
				{children()}
			</CreateDatabaseGAPopoverItemSpotlight>
		);
	};

	return {
		ati: Ati.parse('ati:cloud:confluence:database'),
		children: formatMessage(messages.title),
		onClick: enforceStorageLimit(handleClick),
		elemBefore: <DatabaseIcon label="" />,
		...(!shouldBlockCreate && { href: createHref }),
		...(isSpotlightOpen && isDatabaseGAOnboardingActive && { Wrapper: DatabaseSpotlightWrapper }),
		testId: `create-menu-${shouldBlockCreate ? 'button' : 'link'}-item`,
	};
}
