import React from 'react';

import { Box, Flex, xcss } from '@atlaskit/primitives';
import Lozenge from '@atlaskit/lozenge';

const titleSpacingStyles = xcss({
	marginRight: 'space.075',
});

export const ItemTitleWithLozenge = ({ children, lozenge }) => (
	<Flex alignItems="center" justifyContent="space-between">
		<Box xcss={titleSpacingStyles}>{children}</Box>
		<Lozenge appearance="new">{lozenge}</Lozenge>
	</Flex>
);
