import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { MenuItem, MixedMenuItems } from '@atlassian/universal-create';

import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { useRouteDataRef } from '@confluence/route-manager/entry-points/RouteState';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { END } from '@confluence/navdex';
import { usePageContentId, usePageSpaceKey } from '@confluence/page-context';
import { useIsLivePagesFeatureEnabled } from '@confluence/live-pages-utils/entry-points/useIsLivePagesFeatureEnabled';
import {
	useCreateSpaceExperiment,
	useCanCreateSpace,
} from '@confluence/create-space/entry-points/createSpace';
import type { FlagsStateContainer } from '@confluence/flags';
import { fg } from '@confluence/feature-gating';

import { useSpaceEnabledContentTypes } from '../../CreateContentPopover/useSpaceEnabledContentTypes';
import { useIsCreateMenuLoomRecorderEntryPointEnabled } from '../../CreateContentPopover/CreateContentPopoverItems';

import { useLiveDocItem } from './useLiveDocItem';
import { usePageItem } from './usePageItem';
import { useWhiteboardItem } from './useWhiteboardItem';
import { useDatabaseItem } from './useDatabaseItem';
import { useBlogItem } from './useBlogItem';
import { useEmbedItem } from './useEmbedItem';
import { useSpaceItem } from './useSpaceItem';
import { useLoomItem } from './useLoomItem';

const ANALYTICS_EVENT_SOURCE = 'globalCreateContentPopover';

const messages = defineMessages({
	moreFromAtlassian: {
		id: 'create-blank-fabric-page.universal-create.suggestions',
		defaultMessage: 'Suggestions',
	},
});

export type UseMenuItemProps = {
	parentId?: string;
	source: string;
	spaceKey?: string;
	onClick: (actionSubjectId: string) => void;
	flags?: FlagsStateContainer;
};

export type UseMenuItemReturnProps = MenuItem;

export function useMenuItems(flags: FlagsStateContainer, closeMenu?: () => void): MixedMenuItems {
	const [spaceKey] = usePageSpaceKey();
	const [contentId] = usePageContentId();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const routeDataRef = useRouteDataRef();
	const isOnEditRoute = useIsEditorPage();
	const isLivePage = useIsCurrentPageLive();

	const handleAnalyticsAndResetStickySearch = useCallback(
		(actionSubjectId: string) => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId,
					source: ANALYTICS_EVENT_SOURCE,
					attributes: {
						navdexPointType: END,
						isOnEditRoute,
						currentRouteName: routeDataRef?.current?.routeName,
						currentRouteIsLive: isLivePage,
					},
				},
			}).fire();
		},
		[createAnalyticsEvent, isOnEditRoute, routeDataRef, isLivePage],
	);

	const onClick = useCallback(
		(actionSubjectId: string) => {
			handleAnalyticsAndResetStickySearch(actionSubjectId);
			closeMenu?.();
		},
		[handleAnalyticsAndResetStickySearch, closeMenu],
	);

	const {
		isCreateWhiteboardEnabled,
		isCreateBlogEnabled,
		isCreateLiveDocEnabled,
		isCreateDatabaseEnabled,
		isCreateSmartLinkEnabled,
	} = useSpaceEnabledContentTypes(spaceKey);

	const { isTwoPageTypesExperience } = useIsLivePagesFeatureEnabled();

	const { isSpaceTypesCreationFlowEnabled } = useCreateSpaceExperiment();
	const { canCreateSpace } = useCanCreateSpace();
	const isCreateSpaceFromGlobalCreateEnabled = canCreateSpace && isSpaceTypesCreationFlowEnabled;
	const isLoomEntryPointEnabled = useIsCreateMenuLoomRecorderEntryPointEnabled();

	const liveDoc = useLiveDocItem({
		source: 'globalCreateDropdown-liveDoc',
		parentId: contentId,
		spaceKey,
		onClick,
	});

	const page = usePageItem({
		source: 'globalCreateDropdown-page',
		parentId: contentId,
		spaceKey,
		onClick,
	});

	const whiteboard = useWhiteboardItem({
		source: 'globalCreateDropdown-whiteboard',
		parentId: contentId,
		spaceKey,
		onClick,
	});

	const database = useDatabaseItem({
		source: 'globalCreateDropdown-database',
		parentId: contentId,
		spaceKey,
		onClick,
	});

	const blog = useBlogItem({
		source: 'globalCreateDropdown-blog',
		parentId: contentId,
		spaceKey,
		onClick,
	});

	const embed = useEmbedItem({
		source: 'globalCreateDropdown-smartLinkEmbed',
		parentId: contentId,
		spaceKey,
		onClick,
	});

	const loom = useLoomItem({
		source: 'globalCreateDropdown-smartLinkEmbed',
		parentId: contentId,
		spaceKey,
		onClick,
		flags,
	});

	const space = useSpaceItem({
		source: ANALYTICS_EVENT_SOURCE,
		parentId: contentId,
		spaceKey,
		onClick,
	});

	const nativeItems = {
		items: [
			...((isCreateLiveDocEnabled || fg('confluence_live_docs_force_create_option')) &&
			isTwoPageTypesExperience
				? [liveDoc]
				: []),
			page,
			...(isCreateWhiteboardEnabled ? [whiteboard] : []),
			...(isCreateDatabaseEnabled ? [database] : []),
			...(isCreateBlogEnabled ? [blog] : []),
			...(isCreateSmartLinkEnabled ? [embed] : []),
			...(isCreateSpaceFromGlobalCreateEnabled ? [space] : []),
		],
	};
	const menuItems: MixedMenuItems = [nativeItems];
	const { formatMessage } = useIntl();

	if (isCreateSmartLinkEnabled && isLoomEntryPointEnabled) {
		menuItems.push({
			heading: formatMessage(messages.moreFromAtlassian),
			items: [loom],
		});
	}

	return menuItems;
}
