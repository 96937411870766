/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { type ReactNode, useEffect, useState } from 'react';

import { jsx } from '@compiled/react';
import { IntlProvider, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { type UniversalCreateButtonProps } from '../../types';

type MessageFile = {
	default: { [key: string]: string };
};

export function getLanguageCode(locale: string): string {
	if (['en-GB', 'en-ZZ', 'pt-PT', 'zh-TW'].includes(locale)) {
		return locale;
	} else if (locale === 'zh-HK') {
		return 'zh-TW';
	}
	return locale.split('-')[0];
}

function fetchLanguageFile(code: string): Promise<MessageFile> {
	switch (code) {
		case 'cs':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-cs" */ '../../i18n/cs'
			);
		case 'da':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-da" */ '../../i18n/da'
			);
		case 'de':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-de" */ '../../i18n/de'
			);
		case 'en-GB':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-en-GB" */ '../../i18n/en_GB'
			);
		case 'en-ZZ':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-en-ZZ" */ '../../i18n/en_ZZ'
			);
		case 'en':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-en" */ '../../i18n/en'
			);
		case 'es':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-es" */ '../../i18n/es'
			);
		case 'fi':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-fi" */ '../../i18n/fi'
			);
		case 'fr':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-fr" */ '../../i18n/fr'
			);
		case 'hu':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-hu" */ '../../i18n/hu'
			);
		case 'it':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-it" */ '../../i18n/it'
			);
		case 'ja':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-ja" */ '../../i18n/ja'
			);
		case 'ko':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-ko" */ '../../i18n/ko'
			);
		case 'nb':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-nb" */ '../../i18n/nb'
			);
		case 'nl':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-nl" */ '../../i18n/nl'
			);
		case 'pl':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-pl" */ '../../i18n/pl'
			);
		case 'pt':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-pt_BR" */ '../../i18n/pt_BR'
			);
		case 'ru':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-ru" */ '../../i18n/ru'
			);
		case 'sv':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-sv" */ '../../i18n/sv'
			);
		case 'th':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-th" */ '../../i18n/th'
			);
		case 'tr':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-tr" */ '../../i18n/tr'
			);
		case 'uk':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-uk" */ '../../i18n/uk'
			);
		case 'vi':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-vi" */ '../../i18n/vi'
			);
		case 'zh-TW':
		case 'zh-HK':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-zh-TW" */ '../../i18n/zh_TW'
			);
		case 'zh':
			return import(
				/* webpackChunkName: "@atlaskit-internal_async-universal-create-i18n-zh-ZH" */ '../../i18n/zh'
			);
		default:
			throw new Error(`Unable to find translation for locale ${code}`);
	}
}

export const OPERATIONAL_EVENT_TYPE = 'operational';
export const NAVIGATION_CHANNEL = 'navigation';

function Provider({ children }: { children: ReactNode }) {
	const { locale } = useIntl();
	const [isLocaleLoaded, setIsLocaleLoaded] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [messages, setMessages] = useState<MessageFile['default']>({});

	useEffect(() => {
		async function startToFetchMessagesByLocale() {
			const intlLocale = new Intl.Locale(locale.replace('_', '-'));
			try {
				const module = await fetchLanguageFile(getLanguageCode(intlLocale.toString()));
				const newMessages = module?.default || module;
				setMessages(newMessages);
				setIsLocaleLoaded(true);
			} catch (e) {
				createAnalyticsEvent({
					eventType: OPERATIONAL_EVENT_TYPE,
					action: 'not_loaded',
					actionSubject: 'uc_i18n',
					attributes: {
						country: intlLocale.region,
						language: intlLocale.language,
					},
				}).fire(NAVIGATION_CHANNEL);
				setIsLocaleLoaded(true);
			}
		}

		startToFetchMessagesByLocale();
	}, [createAnalyticsEvent, locale]);

	if (!isLocaleLoaded) {
		return children;
	}

	return (
		<IntlProvider locale={locale} key={locale} messages={messages}>
			{children}
		</IntlProvider>
	);
}

export const withIntlProvider =
	<Props extends UniversalCreateButtonProps>(WrappedComponent: React.ComponentType<Props>) =>
	(props: Props) => (
		<Provider>
			<WrappedComponent {...props} />
		</Provider>
	);
