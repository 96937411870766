import { useQuery } from '@apollo/react-hooks';

import { GetCurrentStorageUsageQuery } from './GetCurrentStorageUsage.graphql';
import type { GetCurrentStorageUsageQuery as GetCurrentStorageUsageQueryType } from './__types__/GetCurrentStorageUsageQuery';

interface StorageInfo {
	currentUsage: number;
	isStorageEnforcementGracePeriodComplete?: boolean | null;
	gracePeriodEndDate?: string | null;
	loading: boolean;
	error?: Error;
}

export const useGetCurrentStorageUsage = ({
	skip,
}: {
	skip?: boolean;
} = {}): StorageInfo => {
	const { data, loading, error } = useQuery<GetCurrentStorageUsageQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		GetCurrentStorageUsageQuery,
		{
			skip,
		},
	);

	return {
		currentUsage: data?.storage?.bytesUsed,
		isStorageEnforcementGracePeriodComplete: data?.storage?.isStorageEnforcementGracePeriodComplete,
		gracePeriodEndDate: data?.storage?.gracePeriodEndDate,
		loading,
		error,
	};
};
