import { createContext, useContext } from 'react';

import { Ati } from '@atlassian/ari';

import type {
	MenuControlContext as ContextProps,
	MenuItem,
	MixedMenuItems,
	SimpleMenuItems,
	UniversalCreateItems,
} from './types';

export function isSimpleMenuItems(items: UniversalCreateItems): items is SimpleMenuItems {
	if (Array.isArray(items)) {
		return items.every((item) => {
			return typeof item === 'object' && item !== null && 'ati' in item;
		});
	}

	return false;
}

export function useNormalizeItems(items: UniversalCreateItems): MixedMenuItems {
	const mixedItems: MixedMenuItems = [];
	if (isSimpleMenuItems(items)) {
		mixedItems.push({
			items,
		});
	} else {
		mixedItems.push(...items);
	}

	return mixedItems;
}

export function findSelectedItem(items: MixedMenuItems, selectedItem: Ati): MenuItem | null {
	for (const namedItem of items) {
		for (const menuItem of namedItem.items) {
			if (menuItem.ati.toString() === selectedItem.toString()) {
				return menuItem;
			}
		}
	}
	return null;
}

const defaultFormControls: ContextProps = {
	load: function (): void {},
	reset: function (): void {},
	close: function (): void {},
	mixedItems: [],
};

export const MenuControlContext = createContext<ContextProps>(defaultFormControls);

export function useMenuControl() {
	return useContext(MenuControlContext);
}
