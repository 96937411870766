import React, { useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import VideoIcon from '@atlaskit/icon/core/video';

import { getHashedLoomVideoIdFromUrl } from '@atlassian/analytics-data-privacy';
import { Ati } from '@atlassian/ari';

import {
	LoomCrossJoinPopupCard,
	useLoomCrossJoinUrl,
	useLoomEntryPointVariant,
	useLoomRecorderEntryPoint,
} from '@confluence/loom-utils';
import { useHardStorageEnforcement } from '@confluence/storage-enforcement/entry-points/HardEnforcement/useHardStorageEnforcement';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import { useSpaceId } from '@confluence/space-utils';
import type { FlagsStateContainer } from '@confluence/flags';
import { RecordLoomPopoverItemSpotlight } from '@confluence/loom-onboarding';
import { useSkippableCoordination } from '@confluence/skippable-coordination-client';
import { fg } from '@confluence/feature-gating';

import type { UseMenuItemProps, UseMenuItemReturnProps } from '.';

import { buildCreationLink } from '../../CreateContentRouteComponent';
import { ItemTitleWithLozenge } from '../ItemTitle';

const ANALYTICS_EVENT_SOURCE = 'globalCreateContentPopover';

const CROSS_JOIN = 'CROSS_JOIN';

const messages = defineMessages({
	title: {
		id: 'create-blank-fabric-page.universal-create.loom.title',
		defaultMessage: 'Loom video',
		description:
			'title of the global create menu item indicating that this option will trigger a Loom video recording flow',
	},
	crossJoinPopupTitle: {
		id: 'create-blank-fabric-page.universal-create.loom.crossJoinPopupTitle',
		defaultMessage: 'Share your work with a video',
		description: 'Title of a popup card advertising an opportunity to try Loom',
	},
	crossJoinPopupDescription: {
		id: 'create-blank-fabric-page.universal-create.loom.crossJoinPopupDescription',
		defaultMessage:
			'Save time and engage your team with a video walkthrough, demo, update, and more.',
		description: 'Description inside a popup card advertising an opportunity to try Loom',
	},
	crossJoinCTA: {
		id: 'create-blank-fabric-page.universal-create.loom.crossJoinCTA',
		defaultMessage: 'Try now',
		description: 'text on button that launches a cross-join flow for Loom',
	},
	crossJoinLozenge: {
		id: 'create-blank-fabric-page.universal-create.loom.cross-join-lozenge',
		defaultMessage: 'Join',
		description:
			'label advertising the ability to join teammates in using the the Loom video recording feature from the global and contextual menus',
	},
});

export function useLoomCrossJoinItem(error?: boolean, loading?: boolean): UseMenuItemReturnProps {
	const { formatMessage } = useIntl();

	const href = useLoomCrossJoinUrl();

	const PopupWrapper = ({ children }) => (
		<LoomCrossJoinPopupCard
			description={formatMessage(messages.crossJoinPopupDescription)}
			entryPointId="loom-confluence-create-menu"
			shouldRenderToParent={false} // need to overwrite the default value
			title={formatMessage(messages.crossJoinPopupTitle)}
			sourceActionSubjectId="createLoomPopoverItem"
			triggerLink={(_, triggerProps) =>
				children({
					triggerProps,
				})
			}
		/>
	);

	return {
		ati: Ati.parse('ati:cloud:confluence:loom-cross-join'),
		...(!error && !loading && { href }),
		isLoading: loading,
		target: '_blank',
		children: (
			<ItemTitleWithLozenge lozenge={formatMessage(messages.crossJoinLozenge)}>
				{formatMessage(messages.title)}
			</ItemTitleWithLozenge>
		),
		Wrapper: PopupWrapper,
		elemBefore: <VideoIcon label="" />,
	};
}

export const useRecordLoomItem = ({
	parentId,
	source,
	spaceKey,
	onClick,
	flags,
}: UseMenuItemProps & {
	flags: FlagsStateContainer;
}): UseMenuItemReturnProps => {
	const { formatMessage } = useIntl();
	const spaceId = useSpaceId();
	const { push } = useRouteActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { openLoomRecorder, isLoomRecorderInitialized, hasRecordingAccess } =
		useLoomRecorderEntryPoint({
			entryPointLocation: ANALYTICS_EVENT_SOURCE,
			flags,
		});

	const { enforceStorageLimit, shouldBlockCreate } = useHardStorageEnforcement({
		source: 'record-loom-popover-item',
	});

	const handleClick = useCallback(() => {
		onClick('createLoomPopoverItem');

		void openLoomRecorder({
			onInsert: (videoUrl: string) => {
				const createUrl = buildCreationLink({
					contentType: 'smartLinkEmbed',
					parentPageId: parentId,
					withFallback: true,
					smartLinkCreateSource: 'loomRecordSDK',
					smartLinkEmbedUrl: videoUrl,
					source,
					spaceKey,
				});

				push(createUrl);

				void getHashedLoomVideoIdFromUrl(videoUrl).then((hashedVideoId) => {
					createAnalyticsEvent({
						type: 'sendTrackEvent',
						data: {
							action: 'insertVideo',
							actionSubject: 'loom',
							source,
							objectType: 'embed',
							containerType: 'space',
							containerId: spaceId,
							attributes: {
								location: 'contentTreeItem',
								video_id: hashedVideoId,
							},
						},
					}).fire();
				});
			},
			// intentionally not localized because Loom RecordSDK does not support i18n
			insertButtonText: 'Insert video',
			location: 'topNav',
		});
	}, [onClick, openLoomRecorder, parentId, source, spaceKey, push, createAnalyticsEvent, spaceId]);
	const shouldShowLoomGAOnboarding =
		isLoomRecorderInitialized && hasRecordingAccess && fg('confluence_m25_onboarding_spotlights');

	const [isLoomGAOnboardingActive, stopLoomGAOnboarding] = useSkippableCoordination(
		'cc-loom-onboarding-spotlight-creation',
		!shouldShowLoomGAOnboarding || shouldBlockCreate,
	);

	const [isGASpotlightOpen, setIsGASpotlightOpen] = useState(true);

	const handleSpotlightClose = useCallback(() => {
		if (isLoomGAOnboardingActive) {
			setIsGASpotlightOpen(false);
			void stopLoomGAOnboarding();
		}
	}, [stopLoomGAOnboarding, isLoomGAOnboardingActive]);

	const RecordLoomSpotlightWrapper = ({ children }) => (
		<RecordLoomPopoverItemSpotlight
			onRecord={enforceStorageLimit(handleClick)}
			onClose={handleSpotlightClose}
			source={source}
			shouldWatchTarget
		>
			{children()}
		</RecordLoomPopoverItemSpotlight>
	);

	return {
		ati: Ati.parse('ati:cloud:confluence:video'),
		children: formatMessage(messages.title),
		isLoading: !isLoomRecorderInitialized,
		elemBefore: <VideoIcon label="" />,
		onClick: enforceStorageLimit(handleClick),
		...(shouldShowLoomGAOnboarding &&
			isLoomGAOnboardingActive &&
			isGASpotlightOpen && { Wrapper: RecordLoomSpotlightWrapper }),
	};
};

export function useLoomItem(
	props: UseMenuItemProps & {
		flags: FlagsStateContainer;
	},
): UseMenuItemReturnProps {
	const { entryPointVariant, error, loading } = useLoomEntryPointVariant();
	const recordItem = useRecordLoomItem(props);
	const crossJoinItem = useLoomCrossJoinItem(error, loading);
	return entryPointVariant === CROSS_JOIN ? crossJoinItem : recordItem;
}
