import React, { useCallback, useContext, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import GlobeIcon from '@atlaskit/icon/core/globe';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box } from '@atlaskit/primitives';

import { Ati } from '@atlassian/ari';

import { AppNavigationContext } from '@confluence/app-navigation-context';
import { useHardStorageEnforcement } from '@confluence/storage-enforcement/entry-points/HardEnforcement/useHardStorageEnforcement';
import { useCreateSpaceStore } from '@confluence/create-space/entry-points/createSpace';
import { CREATE_SPACE_DIALOG, preloadWebItemLocation } from '@confluence/web-item-location';

import type { UseMenuItemProps, UseMenuItemReturnProps } from '.';

const messages = defineMessages({
	title: {
		id: 'create-blank-fabric-page.universal-create.space.title',
		defaultMessage: 'Space',
		description: 'title indicating that this option will create a space',
	},
});

export function useSpaceItem({ source }: UseMenuItemProps): UseMenuItemReturnProps {
	const { formatMessage } = useIntl();
	const [, { openDrawer }] = useCreateSpaceStore();
	const { enforceStorageLimit } = useHardStorageEnforcement({
		source: 'create-space-button',
	});
	const { resetStickySearchRef, createContentPopoverButtonRef } = useContext(AppNavigationContext);
	const createSpaceButtonRef = useRef<HTMLElement | null>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleClick = useCallback(
		(e: React.MouseEvent | React.KeyboardEvent) => {
			resetStickySearchRef?.current?.();
			const location = 'topCreateNav';
			openDrawer(createContentPopoverButtonRef || createSpaceButtonRef, location);
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'createSpaceButton',
					source,
					attributes: {
						location,
					},
				},
			}).fire();
			e.stopPropagation();
		},
		[createAnalyticsEvent, createContentPopoverButtonRef, openDrawer, resetStickySearchRef, source],
	);

	const preloadCreateSpace = () => {
		void preloadWebItemLocation({
			location: CREATE_SPACE_DIALOG,
		});
	};

	const PreloadSpaceWrap = ({ children }) => (
		<Box onMouseDown={preloadCreateSpace}>{children()}</Box>
	);

	return {
		ati: Ati.parse('ati:cloud:confluence:space'),
		children: formatMessage(messages.title),
		onClick: enforceStorageLimit(handleClick),
		elemBefore: <GlobeIcon label="" />,
		testId: 'create-menu-button-item',
		Wrapper: PreloadSpaceWrap,
	};
}
