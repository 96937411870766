import { LoadableLazy } from '@confluence/loadable';
export { PageHeaderLoomButtonSpotlight } from './PageHeaderLoomButtonSpotlight';
export { RecordLoomPopoverItemSpotlight } from './RecordLoomPopoverItemSpotlight';
export { InlineCommentLoomButtonSpotlight } from './InlineCommentLoomButtonSpotlight';
export { EditorToolbarLoomButtonSpotlight } from './EditorToolbarLoomButtonSpotlight';

export const LoomMigrationModalManager = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-LoomMigrationModalManager" */ './LoomMigrationModalManager'
			)
		).LoomMigrationModalManager,
});
