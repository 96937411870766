import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import PageIcon from '@atlaskit/icon/core/page';

import { Ati } from '@atlassian/ari';

import { useHardStorageEnforcement } from '@confluence/storage-enforcement/entry-points/HardEnforcement/useHardStorageEnforcement';

import type { UseMenuItemProps, UseMenuItemReturnProps } from '.';

import { buildCreationLink } from '../../CreateContentRouteComponent';
import { useHybridLinkClick } from '../../CreateContentPopover/CreateContentPopoverItems/hybridLinkHelper';

const messages = defineMessages({
	title: {
		id: 'create-blank-fabric-page.universal-create.page.title',
		defaultMessage: 'Page',
		description: 'title indicating that this option will create a page',
	},
});

export function usePageItem({
	parentId,
	source,
	spaceKey,
	onClick,
}: UseMenuItemProps): UseMenuItemReturnProps {
	const { formatMessage } = useIntl();

	const { enforceStorageLimit, shouldBlockCreate } = useHardStorageEnforcement({
		source: 'create-page-popover-item',
	});

	const createHref = buildCreationLink({
		contentType: 'page',
		parentPageId: parentId,
		withFallback: true,
		source,
		spaceKey,
	});

	const hybridPageLinkClick = useHybridLinkClick(createHref);

	const handleClick = useCallback(
		(e: React.MouseEvent | React.KeyboardEvent) => {
			onClick('createPagePopoverItem');
			hybridPageLinkClick(e);
		},
		[onClick, hybridPageLinkClick],
	);

	return {
		ati: Ati.parse('ati:cloud:confluence:page'),
		children: formatMessage(messages.title),
		onClick: enforceStorageLimit(handleClick),
		elemBefore: <PageIcon label="" />,
		...(!shouldBlockCreate && { href: createHref }),
		testId: `create-menu-${shouldBlockCreate ? 'button' : 'link'}-item`,
	};
}
