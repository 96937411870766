import { useEffect, useState } from 'react';

import { bindAll } from 'bind-event-listener';
import { useDebouncedCallback } from 'use-debounce';

export const RESIZING_DEBOUNCED = 100;

const MOBILE_WIDTH_BREAKPOINT = 767;

export function useIsResponsiveView() {
	const [isResponsiveMode, setIsResponsiveMode] = useState(
		window.innerWidth <= MOBILE_WIDTH_BREAKPOINT,
	);

	const [handleResizeDebounced] = useDebouncedCallback(() => {
		const isCurrentlyMobile = window.innerWidth <= MOBILE_WIDTH_BREAKPOINT;
		setIsResponsiveMode((prev) => (prev !== isCurrentlyMobile ? isCurrentlyMobile : prev));
	}, RESIZING_DEBOUNCED);

	useEffect(() => {
		let unbind;
		unbind = bindAll(window, [
			{
				type: 'resize',
				listener: handleResizeDebounced,
			},
		]);

		return unbind;
	}, [handleResizeDebounced]);

	return isResponsiveMode;
}
