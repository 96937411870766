import React, { Fragment, useCallback, useContext, useState, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import UniversalCreateButton from '@atlassian/universal-create';

import { withFlags } from '@confluence/flags';
import { START } from '@confluence/navdex';
import { Attribution, ErrorDisplay, TransparentErrorBoundary } from '@confluence/error-boundary';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';
import { useLiveDocChangeboarding } from '@confluence/onboarding-live-doc-changeboarding/entry-points/useLiveDocChangeboarding';

import { useMenuItems } from './useMenuItems';

const messages = defineMessages({
	create: {
		id: 'create-blank-fabric-page.universal-create.create.text',
		defaultMessage: 'Create',
		description: 'The Create text that appears in the top nav',
	},
});

const Create = ({ flags }) => {
	const [isOpen, setIsOpen] = useState(false);
	const items = useMenuItems(flags, () => setIsOpen(false));
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { match } = useContext(RoutesContext);
	const isNav4Enabled = useIsNav4Enabled();
	const isOnEditRoute = useIsEditorPage();
	const { formatMessage } = useIntl();
	const [shouldShowPulse, setShouldShowPulse] = useState(false);
	const {
		introSpotlight: { isVisible: shouldShowIntroSpotlight },
		error,
	} = useLiveDocChangeboarding();

	useEffect(() => {
		if (shouldShowIntroSpotlight) {
			setShouldShowPulse(true);
		}
	}, [shouldShowIntroSpotlight]);

	const onCreateClick = useCallback(() => {
		setIsOpen(true);
		setShouldShowPulse(false);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'navigationItem',
				actionSubjectId: 'globalCreateContentPopover',
				source: 'globalNavigation',
				attributes: {
					selectedItemPageContext: match?.name,
					navigationLayer: 'global',
					navigationContainer: 'top',
					navVersion: isNav4Enabled ? '4' : '3',
					navdexPointType: START,
					isOnEditRoute,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, isNav4Enabled, isOnEditRoute, match?.name]);
	return (
		<Fragment>
			<UniversalCreateButton
				items={items}
				onClick={onCreateClick}
				triggerButtonSpotlightTarget="top-nav-create"
				enableTriggerPulse={shouldShowPulse}
				isNav4Enabled={isNav4Enabled}
				isOpen={isOpen}
				product="confluence"
			>
				{formatMessage(messages.create)}
			</UniversalCreateButton>
			{error && <ErrorDisplay error={error} />}
		</Fragment>
	);
};

const CreateWithFlags = withFlags(Create);

export function UniversalCreate() {
	return (
		<TransparentErrorBoundary attribution={Attribution.NAVX}>
			<CreateWithFlags />
		</TransparentErrorBoundary>
	);
}
