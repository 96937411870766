import { useQuery } from 'react-apollo';

import { useSessionData } from '@confluence/session-data';

import { CreateSpaceQuery } from './CreateSpaceQuery.graphql';
import type {
	CreateSpaceQuery as CreateSpaceQueryType,
	CreateSpaceQuery_user_confluence_operations as Operations,
} from './__types__/CreateSpaceQuery';

export const useCanCreateSpace = () => {
	const { isLicensed } = useSessionData();

	const { data: canCreateData } = useQuery<CreateSpaceQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		CreateSpaceQuery,
		{
			skip: !isLicensed,
		},
	);

	const canCreateSpace = Boolean(
		canCreateData?.user?.confluence?.operations.some(
			(obj: Operations | null) => obj?.operation === 'create_space',
		),
	);

	return { canCreateSpace };
};
