import { LoadableLazy } from '@confluence/loadable';

export const DatabaseGAOnboardingModalManager = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-DatabaseGAOnboardingModalManagerComponent" */ './ga/DatabaseGAOnboardingModalManager'
			)
		).DatabaseGAOnboardingModalManagerComponent,
});

export const VersionHistoryModalManager = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-VersionHistoryModalManager" */ './VersionHistory/VersionHistoryModalManager'
			)
		).VersionHistoryModalManager,
});

export { VersionHistoryOnboardingSpotlight } from './VersionHistory/VersionHistoryOnboardingSpotlight';
